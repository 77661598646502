import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
    bigDesktop: true;
  }
}

export enum Color {
  // Primary
  PRM_MAIN = '#0e91ef',
  PRM_LIGHT = '#3eacfa',
  PRM_DARK = '#0b83d9',
  // Secondary
  SEC_MAIN = '#ff661a',
  SEC_DARK = '#f26c0c',
  SEC_LIGHT = '#ff9040',

  // Others
  NAVY_BLUE = '#000f73',
  LIGHT_BLUE = '#63aee30d',
  GREEN_LIGHT = '#71fc14',
  GREY = '#a7a7a7',
  GREY_DARK = '#574D44',
  GREY_LIGHT = '#574d4491',
  DARK = '#343A40',
  WHITE = '#ffffff',
  LIGHT = '#f7f8fa',
  BLACK = '#000000',
  RED = '#e01919',
  ORANGE = '#fd8c63',
  PINK = '#f179b3',
  PURPLE = '#703CEE',
  YELLOW = '#ebf252',
  GREEN = '#2e7d32',
  BORDER = 'rgba(0, 0, 0, 0.12)'
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      bigDesktop: 1800
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: 'p',
          subtitle2: 'p',
          h4: 'p',
          h5: 'p',
          h6: 'p'
        }
      },
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          fontFamily: 'Montserrat',
          color: Color.DARK,
          lineHeight: 1.5,
          fontWeight: 500,

          '&.bold': {
            fontWeight: 700
          },
          '&.semi-bold': {
            fontWeight: 600
          },
          '&.medium': {
            fontWeight: 500
          },
          '&.regular': {
            fontWeight: 400
          },
          '&.light': {
            fontWeight: 300
          }
        },
        h1: {
          fontSize: '4.9rem',
          fontWeight: 500,
          lineHeight: 1.3,

          '&.sm': {
            fontSize: '4rem'
          },
          '&.lg': {
            fontSize: '5.6rem'
          },
          '&.huge': {
            fontSize: '6rem'
          }
        },
        h2: {
          fontSize: '3rem',

          '&.lg': {
            fontSize: '3.5rem'
          }
        },
        h3: {
          fontSize: '2.6rem'
        },
        h4: {
          fontSize: '2.5rem'
        },
        h5: {
          fontSize: '2rem'
        },
        h6: {
          fontSize: '1.8rem'
        },
        subtitle1: {
          fontSize: '1.4rem'
        },
        subtitle2: {
          fontSize: '1.2rem'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.8rem',
          fontFamily: 'Montserrat',
          color: Color.GREY_DARK,
          fontWeight: 500
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: 'Montserrat',
          fontSize: '1.6rem',
          fontWeight: 500,

          '&::placeholder': {
            fontSize: '1.6rem',
            opacity: 0.8
          },

          '&.Mui-disabled.text': {
            WebkitTextFillColor: 'inherit'
          }
        },
        root: {
          '&.Mui-disabled.MuiInputBase-root': {
            '&::before': {
              borderBottom: 'none',
              borderBottomStyle: 'hidden'
            }
          },

          '&.rounded': {
            borderRadius: '5rem'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&[hidden]': {
            display: 'none'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: 2
        },
        input: {
          padding: 14
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          fontFamily: 'Montserrat',
          textTransform: 'initial',
          boxShadow: 'none',
          borderRadius: 5,
          height: '4rem',
          minWidth: '10rem',
          borderColor: 'currentColor',
          paddingRight: 20,
          paddingLeft: 20,

          '&.Mui-disabled': {
            color: 'transparent'
          },

          '&:hover': {
            borderColor: 'currentColor'
          },

          '&.shadow': {
            boxShadow: '0px 0px 20px #06707C48'
          },

          '&.red': {
            backgroundColor: Color.RED
          },

          '&.underline': {
            textDecoration: 'underline',
            paddingRight: 0,
            paddingLeft: 0,
            textUnderlineOffset: 2,

            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'transparent'
            }
          }
        },
        contained: {
          '&.MuiButton-containedInfo': {
            backgroundColor: Color.WHITE,
            color: Color.PRM_MAIN
          }
        },
        outlined: {
          borderWidth: 2,

          '&:hover': {
            borderWidth: 2
          }
        },

        sizeLarge: {
          fontSize: '1.8rem'
        },

        sizeSmall: {
          fontSize: '1.4rem'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 48,
          height: 48
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '2.5rem'
        },
        fontSizeLarge: {
          fontSize: '3.5rem'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          position: 'relative'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& > .MuiTypography-root': {
            color: Color.GREY_DARK,
            fontSize: '1.4rem'
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.nested-list': {
            backgroundColor: Color.LIGHT_BLUE
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.active > .MuiListItemButton-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
          },

          '&.active .MuiSvgIcon-root': {
            fill: Color.PRM_LIGHT
          },

          '&:hover .MuiSvgIcon-root': {
            fill: Color.PRM_LIGHT
          }
        },

        button: {
          minHeight: 48,
          paddingLeft: 2.5,
          paddingRight: 2.5
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          marginRight: '10px !important'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem'
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&:hover, &:focus': {
            color: 'inherit',
            opacity: 0.8
          },

          '&.Mui-active, & > svg': {
            color: 'inherit !important'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '& > *:last-child': {
            padding: '0 2.4rem 2.4rem',

            '& .fixed-positioned': {
              left: '2.4rem',
              right: '2.4rem'
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: '25rem'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: Color.GREY_DARK
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',

          '&.Mui-error': {
            fontSize: '0.8em'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: Color.GREY_DARK,
          fontSize: '1.6rem'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 0,
          color: Color.GREY_DARK,

          '& > em': {
            fontStyle: 'normal'
          }
        },
        standard: {
          '&:not(.MuiTablePagination-select)': {
            paddingBottom: '2.5rem',
            paddingTop: '1rem'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          fontSize: '1.6rem'
        },
        selectLabel: {
          fontSize: '1.4rem'
        },
        selectIcon: {
          '&.MuiSvgIcon-root': {
            right: 0
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: 'center'
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          '&.search-collapse': {
            marginTop: '-2rem',
            marginBottom: '2rem',
            maxWidth: '50%'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: '1.4rem'
        },
        inputRoot: {
          paddingBottom: 5
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          textTransform: 'initial',
          fontFamily: 'Montserrat',
          fontWeight: 500
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          textTransform: 'initial',
          fontFamily: 'Montserrat',
          fontWeight: 500
        }
      }
    }
  },
  palette: {
    primary: {
      main: Color.PRM_MAIN,
      light: Color.PRM_LIGHT,
      contrastText: Color.WHITE
    },
    secondary: {
      main: Color.SEC_MAIN,
      light: Color.SEC_LIGHT,
      contrastText: Color.WHITE
    }
  }
});
