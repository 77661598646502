export enum TagTypes {
  USERS = 'users',
  USER_LOGS = 'user-logs',
  TENANTS = 'tenants',
  ENTERPRISES = 'enterprises',
  FARM_GROUPS = 'farm-groups',
  FARMS = 'farms',
  FIELDS = 'fields',
  CROPS = 'crops',
  CROP_CLASSES = 'crop-classes',
  CROP_TYPES = 'crop-types',
  CROP_GROWTH_STAGES = 'crop-growth-stages',
  GROWTH_STAGES = 'growth-stages',
  MODEL_OUTPUTS = 'model-outputs',
  VARIETIES = 'varieties',
  REGIONS = 'regions',
  BANDING_VALUES = 'banding-values',
  UNITS = 'units'
}

type QueryKeys = {
  list: () => [{ type: TagTypes; id: 'list' }];
  single: (id: string) => [{ type: TagTypes; id: string }];
};

const getQueryKeys = (key: TagTypes): QueryKeys => ({
  list: () => [{ type: key, id: 'list' }],
  single: (id: string) => [{ type: key, id }]
});

export const queryTags = Object.values(TagTypes).reduce(
  (acc, tag) => ({ ...acc, [tag]: getQueryKeys(tag) }),
  {} as Record<TagTypes, QueryKeys>
);
