import { FC, PropsWithChildren } from 'react';
import { LoadScript } from '@react-google-maps/api';

import { Mutable } from 'interfaces/general';

const LIBRARIES = ['drawing'] as const;

const MapLoaderContext: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAP_KEY as string}
      libraries={LIBRARIES as Mutable<typeof LIBRARIES>}
    >
      {children}
    </LoadScript>
  );
};

export default MapLoaderContext;
