import { ICrop } from './cropInterface';
import { LatLngObject } from './mapInterface';

export interface IFarmGroup {
  id: string;
  name: string;
  createdAt: string;
  enterpriseId: string;
}

export type FarmGroupFormType = Omit<IFarmGroup, 'createdAt'>;

export type FieldsStatsType = {
  growthStageMean: number;
  yieldFieldMean: number;
  laiMean: number;
  canopyNMean: number;
  ndwiMean: number;
  ndviMean: number;
};

export type FieldFeaturesStatsType = {
  [key in FieldFeatures]?: number;
};

export interface IFarm {
  id: string;
  name: string;
  city: string;
  country: string;
  address: string;
  address2?: string;
  accountManager: string;
  totalSize: number;
  totalArableSize: number;
  seasonStart: string;
  seasonEnd?: string;
  postCode?: string;
  contact?: string;
  logo?: string;
  farmGroupId?: string;
  stats?: FieldsStatsType;
  createdAt: string;
}

export type FarmMeanStatsType = { stats: { [key in FieldFeatures]?: number }; id: string };

export type FarmFormType = Omit<IFarm, 'createdAt'>;

export interface IRegion {
  id: string;
  name: string;
  createdAt: string;
}

export type RegionFormType = Omit<IRegion, 'createdAt'>;

export type GeoJSONType = {
  type: string;
  coordinates: number[][] | number[][][] | number[][][][];
};

export enum FieldStatus {
  New = 'N',
  Approved = 'A',
  Rejected = 'R',
  Updated = 'U',
  Inactive = 'I'
}

export type WeatherType = {
  date: string;
  temp: number;
  radiationFlux: number;
  precipitation: number;
};

export interface IFieldModelOutput {
  min?: { [key in FieldFeatures]?: number };
  max?: { [key in FieldFeatures]?: number };
  mean?: { [key in FieldFeatures]?: number };
  pointsNum?: number;
  pointsSatNum?: number;
  outputDate?: string;
}
export interface IField {
  id: string;
  name: string;
  code: string;
  area: number;
  boundary: string;
  farmId: string;
  centroid?: string;
  shapefile?: string;
  region?: Pick<IRegion, 'id' | 'name'>;
  crop?: Pick<ICrop, 'id' | 'cropType' | 'sowingDate' | 'variety'>;
  image?: string;
  geojson?: GeoJSONType;
  status?: FieldStatus;
  modelOutput?: [IFieldModelOutput];
  weather?: WeatherType[];
  weatherHistory?: WeatherType[];
  harvestYear?: string;
  createdAt: string;
  cropTypeId?: string;
  enterpriseId?: string;
}

export interface IFields {
  count: number;
  results: IField[];
  next?: string;
  previous?: string;
  stats?: FieldsStatsType;
}

export type FieldsMeanStatsType = {
  count: number;
  results: IField[];
  next?: string;
  previous?: string;
  stats: FieldFeaturesStatsType;
};

export type FieldFormType = Omit<IField, 'createdAt'>;

export type FieldSubmitFormType = FieldFormType & {
  regionId?: string;
};

export enum FieldFeatures {
  YIELD_FORECAST = 'yieldField',
  GROWTH_STAGE = 'growthStage',
  LAI_BIOMASS = 'lai',
  NDVI = 'ndvi',
  // SATELLITE_IMAGE = 'ST',
  CANOPY_NITROGEN = 'canopyN',
  CROP_MOISTURE = 'ndwi',
  NEE = 'neeCum'
}

export interface IColourBanding {
  id: string;
  name: FieldFeatures | '';
  minValue: number;
  maxValue: number;
  colours: string[];
  createdAt: string;
  step?: number;
}

export type ColourBandingFormType = Omit<IColourBanding, 'createdAt'>;

export type ColourBandingValues = Omit<IColourBanding, 'createdAt' | 'id' | 'name'>;

export type PointValues = {
  [key in FieldFeatures]?: number;
} & { coord: LatLngObject };

export interface Stats {
  min?: number;
  max?: number;
  mean?: number;
  png?: string;
}
export type IModelOutput = {
  id: string;
  field: Pick<IField, 'id' | 'name' | 'code'>;
  createdAt: string;
  outputDate: string;
  pointValues?: PointValues[];
  pointSatValues?: PointValues[];
  fieldId?: string;
  png: { [key in FieldFeatures]?: string };
  min: { [key in FieldFeatures]?: number };
  max: { [key in FieldFeatures]?: number };
  mean: { [key in FieldFeatures]?: number };
};

export type ModelOutputDate = Pick<IModelOutput, 'id' | 'outputDate'>;

export enum FarmTabs {
  ASSESS_WHOLE_FARM = 'asses-whole-farm',
  ANALYSE_FIELDS = 'analyse-fields',
  NITROGEN_CALCULATOR = 'nitrogen-calculator',
  VERIFY_CARBON = 'verify-carbon',
  COMPARE_WEATHER = 'weather-comparison'
}

export enum StrategyEnum {
  Winner = 'winner',
  Level = 'level'
}
export enum FertiliserTypeEnum {
  Liquid = 'liquid',
  Solid = 'solid'
}
export interface CalculatorState {
  strategy: StrategyEnum;
  flex: number;
  nApplicationRate: number;
  appliedToDate: number;
  thisApplicationRate: number;
  fertiliserType: FertiliserTypeEnum;
  nConcentration: number;
  requirement: number;
}

export interface INitrogenResponse {
  nitrogenMap: {
    image: string;
    colours: Record<number, [number, number, number]>;
    totalColours: Record<number, [number, number, number]>;
  };
}
