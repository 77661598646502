import { FC, Suspense } from 'react';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate } from 'react-router-dom';

import { APP_ROUTES } from 'routes';
import { Pages } from 'interfaces/general';
import { history } from 'utils/history';
import { lazyPreload } from 'utils/lazyPreload';
import MainLayout from 'layouts/main';
import ProtectRoute from 'components/ProtectRoute';
import FullScreenLoader from 'components/FullScreenLoader';

const LoginPage = lazyPreload(() => import('pages/login'));
const NotFoundPage = lazyPreload(() => import('pages/404'));
const UnauthorizedPage = lazyPreload(() => import('pages/403'));
const ForgotPasswordPage = lazyPreload(() => import('pages/forgot-password'));
const ResetPasswordPage = lazyPreload(() => import('pages/reset-password'));
const VerifyAccountPage = lazyPreload(() => import('pages/verify-account'));

const App: FC = () => {
  return (
    // /eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // /@ts-expect-error
    <HistoryRouter history={history}>
      <Suspense fallback={<FullScreenLoader />}>
        <Routes>
          <Route path="/" element={<ProtectRoute to={Pages.HOME} />}>
            <Route index element={<Navigate to={Pages.LOGIN} replace />} />
            <Route path={Pages.LOGIN} element={<LoginPage />} />
            <Route path={Pages.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
            <Route path={Pages.RESET_PASSWORD} element={<ResetPasswordPage />} />
            <Route path={Pages.VERIFY_ACCOUNT} element={<VerifyAccountPage />} />
          </Route>

          <Route element={<MainLayout />}>
            {APP_ROUTES.map(({ path, component: Component, allowedRoles }) => (
              <Route key={path} element={<ProtectRoute allowedRoles={allowedRoles} />}>
                <Route path={path} element={<Component />} />
              </Route>
            ))}
          </Route>

          <Route path={Pages.UNAUTHORIZED} element={<UnauthorizedPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </HistoryRouter>
  );
};

export default App;
