import { makeStyles } from 'tss-react/mui';

import { Color } from 'theme/muiTheme';

export const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  header: {
    padding: '1rem 3rem',
    borderBottom: `1px solid ${Color.BORDER}`
  },
  container: {
    display: 'flex',
    flex: 1
  },
  main: {
    flex: 1,
    padding: '2.5rem 3rem'
  },
  link: {
    display: 'inline-block',
    color: Color.DARK,

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  avatar: {
    marginRight: 'auto',
    width: 100,
    height: 80
  }
}));
