import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FieldFeatures } from 'interfaces/farmInterface';
import { SelectedFieldType } from 'interfaces/mapInterface';

interface FieldsFormState {
  selectedField?: SelectedFieldType;
  feature?: FieldFeatures;
  dates?: string[];
}

const initialState: FieldsFormState = {
  feature: FieldFeatures.YIELD_FORECAST,
  dates: []
};

const FieldsFormSlice = createSlice({
  name: 'fieldsForm',
  initialState,
  reducers: {
    setSelectedField: (state, { payload }: PayloadAction<SelectedFieldType | undefined>) => {
      state.selectedField = payload;
      state.dates = [];
    },
    setSelectedDates: (state, { payload }: PayloadAction<string[]>) => {
      state.dates = payload;
    },
    setSelectedFeature: (state, { payload }: PayloadAction<FieldFeatures | undefined>) => {
      state.feature = payload;
    },
    resetFieldForm: () => initialState
  }
});

export const { setSelectedField, setSelectedFeature, setSelectedDates, resetFieldForm } =
  FieldsFormSlice.actions;

export default FieldsFormSlice.reducer;
