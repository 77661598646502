import HomeIcon from '@mui/icons-material/Home';
import PaletteIcon from '@mui/icons-material/Palette';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

import { Pages } from 'interfaces/general';
import { lazyPreload } from 'utils/lazyPreload';
import type { AppRoutesType, IAppRoute } from 'interfaces/routeInterface';

const HomePage = lazyPreload(() => import('pages/home'));
const ColourBandingPage = lazyPreload(() => import('pages/colour-banding'));
const UnitsPage = lazyPreload(() => import('pages/units'));
const UserProfilePage = lazyPreload(() => import('pages/profile'));

type RoutesType = IAppRoute & {
  children?: IAppRoute[];
};

export const ROUTES: RoutesType[] = [
  // HOME
  {
    path: Pages.HOME,
    component: HomePage,
    label: 'Home',
    icon: HomeIcon
  },
  // Colour Banding
  {
    path: Pages.COLOUR_BANDING,
    component: ColourBandingPage,
    label: 'Colour Banding',
    icon: PaletteIcon
  },
  // Units
  {
    path: Pages.UNITS,
    component: UnitsPage,
    label: 'Unit Management',
    icon: ModelTrainingIcon
  }
];

const flatRoutes = (routes: RoutesType[]): IAppRoute[] =>
  routes.flatMap(route => (route.children ? flatRoutes(route.children) : [route]));

export const APP_ROUTES = (flatRoutes(ROUTES) as AppRoutesType[]).concat({
  path: Pages.PROFILE,
  component: UserProfilePage
});
