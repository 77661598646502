import type { DialogActionsProps } from 'components/DialogActions';
import { FieldsStatsType, IField } from './farmInterface';
import { UserRole, UserType } from './userInterface';

export type ExtraFields<T> = Partial<Record<keyof T, string>> & { label?: string };

export type EditFormComponentProps<T> = DialogActionsProps & {
  initialValues?: T;
  extraFields?: ExtraFields<T>;
};

export type ViewComponentProps<T> = {
  data: T;
};

export interface CommonExtraFields {
  id?: string;
  tenantId?: string;
  enterpriseId?: string;
  farmGroupId?: string;
  farmId?: string;
  fieldId?: string;
  cropClassId?: string;
  cropTypeId?: string;
  cropId?: string;
  harvestYear?: string;
  regionId?: string;
}

export interface Params {
  page?: number;
  limit?: number;
  ordering?: string;
  select?: string;
  search?: string;
  roleId?: UserRole;
  tenantId?: string;
  enterpriseId?: string;
  farmId?: string;
  userType?: UserType;
  [key: string]: unknown;
}

export interface GetAllResponse<T> {
  count: number;
  results: T[];
  next?: string;
  previous?: string;
}

export type Mutable<T> = {
  -readonly [K in keyof T]: Mutable<T[K]>;
};

export enum Pages {
  HOME = '/home',
  COLOUR_BANDING = '/colour-banding-values',
  UNITS = '/units',
  LOGIN = '/login',
  UNAUTHORIZED = '/unauthorized',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password/:userId/:token',
  VERIFY_ACCOUNT = '/verify-email/:userId/:token',
  PROFILE = '/profile'
}
