import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import FullScreenLoader from 'components/FullScreenLoader';
import MainSidebar from 'components/Sidebar';
// import MainHeader from './Header';
import { useStyles } from './styles/main';

const MainLayout: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {/* <MainHeader /> */}

      <div className={classes.container}>
        <MainSidebar />

        <main className={classes.main}>
          <Suspense fallback={<FullScreenLoader />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
