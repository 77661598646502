import {
  IAccountVerification,
  IForgotPassword,
  ILogin,
  ILoginResponse,
  IResetPassword
} from 'interfaces/authInterface';
import { Pages } from 'interfaces/general';
import { UserRole } from 'interfaces/userInterface';
import { showStandAloneAlert } from 'contexts/AlertContext';
import { setCredentials, logout } from 'redux/slices/authSlice';
import { history } from 'utils/history';
import { baseApi } from './baseApi';

const ALLOWED_ROLES = [UserRole['Super User'], UserRole.Farm, UserRole.Enterprise, UserRole.Tenant];

export const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    loginUser: builder.mutation<ILoginResponse, ILogin>({
      query: data => ({
        url: '/auth/login/',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Only farmers and super users are allowed to login
          if (!ALLOWED_ROLES.includes(UserRole[data.user.role])) {
            history.replace(Pages.UNAUTHORIZED);
            return;
          }

          dispatch(
            setCredentials({
              user: data.user,
              refreshToken: data.token.refresh,
              accessToken: data.token.access
            })
          );
          history.replace(Pages.HOME);
        } catch (__) {}
      }
    }),
    forgotPassword: builder.mutation<null, IForgotPassword>({
      query: data => ({
        url: '/auth/reset-password/',
        method: 'POST',
        body: data
      })
    }),
    resetPassword: builder.mutation<null, IResetPassword>({
      query: data => ({
        url: '/auth/reset-password/',
        method: 'PATCH',
        body: data
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;

          showStandAloneAlert({
            status: 'success',
            message: 'Your password has been updated successfully.',
            duration: 7000
          });

          history.replace(Pages.LOGIN);
        } catch (__) {}
      }
    }),
    verifyAccount: builder.mutation<null, IAccountVerification>({
      query: data => ({
        url: '/auth/verify-email/',
        method: 'PATCH',
        body: data
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          showStandAloneAlert({
            status: 'success',
            message:
              'Your email address has been verified successfully. You can now login to your account.',
            duration: 7000
          });
        } catch (__) {
        } finally {
          history.replace(Pages.LOGIN);
        }
      }
    }),
    sendVerificationEmail: builder.mutation<null, { id: string }>({
      query: data => ({
        url: '/auth/verify-email/',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          showStandAloneAlert({
            status: 'success',
            message: 'Verification email has been sent successfully.'
          });
        } catch (__) {}
      }
    }),
    logoutUser: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/logout/',
        method: 'POST',
        credentials: 'include'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logout());
        } catch (__) {
        } finally {
          dispatch(logout());
          history.replace(Pages.LOGIN);
        }
      }
    })
  }),
  overrideExisting: false
});

export const {
  useLoginUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogoutUserMutation,
  useVerifyAccountMutation,
  useSendVerificationEmailMutation
} = authApi;
